<template>
  <section id="home" v-if="landingCollection && personalCollection">
    <!-- Main content -->
    <div class="cover-content">
     <v-row>
      <v-col
        cols="12"
        lg="7"
        md="6"
        class="d-flex justify-center"
      >
          <div class="content-container">
            <div v-if="personalCollection.isAllowed" style="text-align: left;">
              <h1 class="name">
                <span>Hey, I'm {{personalCollection.firstName}}.</span>
              </h1>
              <h2 class="job-title subtitle-1">{{ personalCollection.currentJobTitle }}</h2>
            </div>
            <v-btn
              router
              :to="{ name: 'Portfolio' }"
              class="mt-2 btn-outline"
            >
              View My Portfolio
            </v-btn>
          </div>
      </v-col>
      <v-col
        cols="12"
        lg="5"
        md="6"
        class="hidden-sm-and-down"
      >
        <div class="image-box-container ml-9">
          <div class="black-box"></div>
          <div class="image-box">
              <v-img :src="personalCollection.profilePitcture.url" ma max-width="300"/>
          </div>
        </div>
      </v-col>
     </v-row>
    </div>
  </section>
</template>

<script>
import firebaseGetCollection from '@/mixins/firebase8/collection/get';

export default {
  name: 'Home',

  data: () => ({
    landingCollectionRaw: null,
    personalCollectionRaw: null,
  }),

  computed: {
    landingCollection() {
      return this.landingCollectionRaw?.results?.[0] || null;
    },
    personalCollection() {
      return this.personalCollectionRaw?.results?.[0] || null;
    },
  },

  async created() {
    await this.subToCollection();
  },

  beforeDestroy() {
    this.unSubToCollection();
  },

  methods: {
    async subToCollection() {
      this.landingCollectionRaw = await firebaseGetCollection('landing');
      this.personalCollectionRaw = await firebaseGetCollection('personal');
    },
    unSubToCollection() {
      this.landingCollectionRaw = null;
      this.personalCollectionRaw = null;
    },
  },
};
</script>

<style scoped>
body {
  background-color: #1c1c1c;
  color: #ffffff;
  margin: 0;
}

.cover-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px);
  color: #fff;
  text-align: center;
}

.image-box-container{
  position: relative;
  padding-bottom: 350px;
}

.black-box{
  position: absolute;
  background-color: black;
  width: 400px;
  height: 400px;
  top: -80px;
  border-radius: 1%;
}

.image-box{
  position: absolute;
  left: 49px;
  bottom: -30px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 28, 0.7);
}

.content-container {
  position: relative;
  z-index: 1;
  max-width: 800px;
  left: 30px;
  padding: 20px;
  animation: fadeIn 1s ease-in-out;
}

.name {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.job-title {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: rgb(174, 174, 174);
}

.projects-button {
  background-color: #446bfc !important;
  color: #ffffff !important;
  border: none !important;
  padding: 10px 20px !important;
  font-size: 1em !important;
  border-radius: 5px !important;
  cursor: pointer;
}

.projects-button:hover {
  background-color: #3655c7 !important;
}

.cover-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 60px);
  object-fit: cover;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
